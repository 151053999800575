/* Text colors */
.text-light-blue {
  color: $color-light-blue;
}

.text-medium-blue {
  color: $color-medium-blue;
}

.text-dark-blue {
  color: $color-dark-blue;
}

.text-accent-blue {
  color: $color-accent-blue;
}

.text-link {
  color: $color-link;
}

.text-geoip {
  color: $color-geoip;
}

.text-minfraud {
  color: $color-minfraud;
}

.text-orange {
  color: $color-orange;
}

.text-green {
  color: $color-green;
}

.text-black {
  color: #000;
}

.text-dark-gray {
  color: $color-dark-gray;
}

/* Background colors */
.bg-dark-blue {
  background-color: $color-dark-blue;
}

.bg-light-blue {
  background-color: $bg-light-blue;
}

.bg-bright-blue {
  background-color: $bg-bright-blue;
}

.form-error {
  color: #d0021b;
}

.box-shadow {
  box-shadow: 0px 3px 4px $box-shadow-color;
}
