@mixin page-intro($image) {
  background-image: $image;
  margin-top: -78px;
}

@mixin page-intro--small($image) {
  @include page-intro($image);

  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  display: grid;
  min-height: 400px;

  @include media-breakpoint-down(xl) {
    background-size: 1900px;
  }
}

@mixin page-intro--large($image) {
  @include page-intro($image);

  min-height: 680px;
  position: relative;
}
