.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  background: #fff;
  flex: 1 0 auto;
  margin: 0;
}
