.pricing-checkmark__heading {
  color: $color-dark-blue;
  padding-bottom: 0px !important;
}

.pricing-checkmark__description {
  align-items: center;
  display: flex;
  gap: 16px;
  text-wrap: nowrap;
}

.pricing-checkmark__wrapper {
  align-items: center;
  background-color: $color-dark-blue;
  border-radius: 50%;
  display: flex;
  height: 25px;
  width: 25px;
}

.pricing-checkmark__check {
  color: $color-green;
  font-size: 40px;
  margin-left: -6px;
}
