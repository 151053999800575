.btn {
  border-radius: 5px;
  color: $color-white;
  font-size: 19px;
  font-weight: 500;
  width: max-content;

  &:hover {
    color: $color-white;
    text-decoration: none;
  }

  &:focus {
    color: $color-white;
    outline: $color-light-blue solid 2px;
  }
}

.btn-sm {
  border: 2px solid;
  font-size: 14px;
}

.btn-geoip,
.btn-geoip:focus {
  background-color: $color-geoip;
  border-color: $color-geoip;

  &:hover {
    background-color: $color-geoip-hover;
  }
}

.btn-minfraud,
.btn-minfraud:focus {
  background-color: $color-minfraud;
  border-color: $color-minfraud;

  &:hover {
    background-color: $color-minfraud-hover;
  }
}

.btn-light-blue,
.btn-light-blue:focus {
  background-color: $bg-light-blue;
  border-color: $color-dark-blue;
  color: $color-dark-blue;

  &:hover {
    background-color: $color-light-blue-hover;
    border-color: $color-dark-blue;
  }
}

.btn-medium-blue,
.btn-medium-blue:focus {
  background-color: $color-medium-blue;
  border-color: $color-medium-blue;

  &:hover {
    background-color: $color-medium-blue-hover;
  }
}

.btn-dark-blue,
.btn-dark-blue:focus {
  background-color: $color-dark-blue;
  border-color: $color-dark-blue;

  &:hover {
    background-color: $color-white;
    border-color: $color-dark-blue;
    color: $color-dark-blue;
  }
}

.btn-dark-blue--inverse,
.btn-dark-blue--inverse:focus {
  background-color: $color-white;
  border-color: $color-dark-blue;
  color: $color-dark-blue;

  &:hover {
    background-color: $color-dark-blue-hover;
    border-color: $color-dark-blue-hover;
    color: $color-white;
  }
}

.btn-green,
.btn-green:focus {
  background-color: $color-green;
  border-color: $color-green;

  &:hover {
    background-color: $color-green-hover;
  }
}

.btn-orange,
.btn-orange:focus {
  background-color: $color-orange;
  border-color: $color-orange;

  &:hover {
    background-color: $color-orange-hover;
  }
}

.btn-orange--inverse,
.btn-orange--inverse:focus {
  background-color: $color-white;
  border: 2px solid $color-orange;
  color: $color-dark-blue;

  &:hover {
    background-color: $color-orange-hover;
    color: $color-white;
  }
}
