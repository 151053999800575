@use '../mixins';

.accuracy-comparison__results-wrapper {
  max-width: 850px;
  scroll-margin-top: 80px;

  @include media-breakpoint-up(md) {
    scroll-margin-top: 180px;
  }
}

.accuracy-comparison__form {
  background-color: $bg-light-blue;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
  padding: 12px;
  margin: 30px 0;
}

.accuracy-comparison__table {
  margin: 0;
}

.accuracy-comparison__table-heading {
  color: $color-dark-blue;
  height: auto;
  margin: 2px;
  padding: 0 8px;
}

.accuracy-comparison__table .comparison-table__attribute {
  background-color: #fff !important;
  padding-left: 8px;
}

.accuracy-comparison__table tr:last-of-type td:last-of-type {
  border-bottom-right-radius: 8px;
}

.accuracy-comparison__table tr:last-of-type td:first-of-type {
  border-bottom-left-radius: 8px;
}

.accuracy-comparison__table-cell {
  font-family: 'Source Code Pro', monospace;
  text-align: right;
}
