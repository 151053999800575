.geoip-exchange__intro {
  @include page-intro--small(
    url(../images/redesign/blue-wave-background-geoip.svg)
  );
}

.geoip-exchange__image {
  background-image: url(../images/redesign/global-network-connectivity.webp);
  background-position: right 35% bottom 100%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 320px;
  position: relative;
  width: 100%;
}

.geoip-exchange__blue-dot-background {
  background-image: url(../images/redesign/blue-dot-background.svg);
  background-size: cover;
}
