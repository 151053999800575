.geoip-demo-form__table-wrapper {
  border-radius: 8px;

  @include media-breakpoint-up(md) {
    overflow: auto;
  }
}

.geoip-demo-form__table {
  font-family:
    Source Code Pro,
    monospace;
  font-size: 14px;
  width: 100%;

  thead tr {
    /* Hide table headers (but not display: none;, for accessibility) */
    @include media-breakpoint-down(md) {
      @include visually-hidden();
    }
  }

  table,
  thead,
  tbody,
  th,
  tr,
  td {
    @include media-breakpoint-down(md) {
      /* Force table to not be like tables anymore */
      display: block;
    }
  }

  td,
  th {
    border-right: 1px solid $color-medium-blue;
    padding: 9px;

    &:last-child {
      border-bottom: none;
      border-right: none;
    }

    @include media-breakpoint-down(md) {
      border-bottom: 1px solid $color-medium-blue;
      border-right: none;
    }
  }

  td {
    color: $color-dark-blue;
    min-height: 40px;
    padding-left: 65%;

    @media only screen and (min-width: 400px) {
      padding-left: 55%;
    }

    @media only screen and (min-width: 500px) {
      padding-left: 50%;
    }

    @include media-breakpoint-down(md) {
      overflow-wrap: break-word;
      position: relative;

      &:before {
        font-weight: 600;
        left: 6px;
        padding: 9px;
        position: absolute;
        top: 0;
        white-space: nowrap;
        width: 45%;
      }

      &:nth-of-type(1):before {
        content: 'IP Address';
      }

      &:nth-of-type(2):before {
        content: 'Location';
      }

      &:nth-of-type(3):before {
        content: 'Network';
      }

      &:nth-of-type(4):before {
        content: 'Postal Code';
      }

      &:nth-of-type(5):before {
        content: 'Approximate Latitude / Longitude*, and Accuracy Radius';
        white-space: normal;
      }

      &:nth-of-type(6):before {
        content: 'ISP / Organization';
      }

      &:nth-of-type(7):before {
        content: 'Domain';
      }

      &:nth-of-type(8):before {
        content: 'Connection Type';
      }
    }

    @include media-breakpoint-up(md) {
      padding-left: 9px;
    }
  }

  th {
    background-color: $color-dark-blue;
    color: $color-white;
    font-weight: 500;
    line-height: 2em;
    vertical-align: bottom;
  }

  tr {
    background-color: #e6f5fc;
    margin-bottom: 20px;

    &:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    &:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    @include media-breakpoint-down(md) {
      border: 1px solid #79bae1;
      border-radius: 8px;
    }

    &:nth-of-type(odd) {
      background-color: #d9e8f0;
    }
  }
}

/* We will lose these tooltips on the mobile version of the table */
.geoip-demo-form__tooltip {
  color: $color-white;
  border-bottom: 2px dashed $color-medium-blue;
  padding-bottom: 4px;

  &:hover {
    color: $color-white;
    text-decoration: none;
  }
}

.geoip-demo-form__ip-address {
  overflow-wrap: break-word;

  @media only screen and (max-width: 400px) {
    max-width: 287px;
  }

  @include media-breakpoint-up(md) {
    max-width: 200px;
  }
}

.geoip-demo-form__coordinates {
  height: 120px;

  @media only screen and (min-width: 400px) {
    height: 106px;
  }

  @media only screen and (min-width: 516px) {
    height: 78px;
  }

  @media only screen and (min-width: 722px) {
    height: 60px;
  }

  @include media-breakpoint-up(md) {
    height: auto;
  }
}

.geoip-demo-form__heading-280 {
  min-width: 280px;
}

.geoip-demo-form__warning-icon {
  color: #e33333;
  font-size: 20px;
  padding-right: 5px;
}

.geoip-demo-form__textarea {
  background-color: #d9e8f0;
  border: none;
  border-radius: 0 0 8px 8px;
  color: $color-dark-blue;
  height: 150px;
  margin-bottom: 20px;
  max-width: none;
  padding: 12px;
  resize: none;
  width: 100%;

  &:focus {
    background-color: #d9e8f0;
  }
}

.geoip-demo-form__label {
  background-color: $color-dark-blue;
  border-radius: 8px 8px 0 0;
  color: $color-white;
  padding: 12px;
  width: 100%;
}
