.enterprise-connect__list {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.enterprise-connect__icon {
  height: 65px;
  padding-bottom: 12px;
}

.enterprise-connect__icon-group {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.enterprise-connect__overview-check {
  color: $color-green;
  font-size: 24px;
  margin-top: -5px;
}
