.page-container {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding-bottom: 32px;
  }

  h1 {
    font-size: 48px;
    font-weight: 700;

    @include media-breakpoint-up(md) {
      font-size: 50px;
    }
  }

  h2 {
    font-size: 28px;
    font-weight: 600;

    @include media-breakpoint-up(md) {
      font-size: 32px;
    }
  }

  h3 {
    font-size: 25px;
    font-weight: 600;
  }

  h4 {
    font-size: 20px;
    font-weight: 600;
  }
}

.heading__intro {
  color: $color-white;
  margin-top: 3rem !important;
  padding-top: 3rem !important;
}
