/* generated by google-webfonts-helper.herokuapp.com */

/* montserrat-300 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-300.eot'); /* IE9 Compat Modes */
  src:
    local('Montserrat Light'),
    local('Montserrat-Light'),
    url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-300.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-300.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-300.woff')
      format('woff'),
    /* Modern Browsers */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-300.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-300.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}
/* montserrat-300italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  src: url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-300italic.eot'); /* IE9 Compat Modes */
  src:
    local('Montserrat Light Italic'),
    local('Montserrat-LightItalic'),
    url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-300italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-300italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-300italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-300italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-300italic.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}
/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src:
    local('Montserrat Regular'),
    local('Montserrat-Regular'),
    url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-regular.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}
/* montserrat-italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src: url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-italic.eot'); /* IE9 Compat Modes */
  src:
    local('Montserrat Italic'),
    local('Montserrat-Italic'),
    url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-italic.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}
/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-500.eot'); /* IE9 Compat Modes */
  src:
    local('Montserrat Medium'),
    local('Montserrat-Medium'),
    url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-500.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-500.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-500.woff')
      format('woff'),
    /* Modern Browsers */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-500.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-500.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}
/* montserrat-500italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  src: url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-500italic.eot'); /* IE9 Compat Modes */
  src:
    local('Montserrat Medium Italic'),
    local('Montserrat-MediumItalic'),
    url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-500italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-500italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-500italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-500italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-500italic.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}
/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-600.eot'); /* IE9 Compat Modes */
  src:
    local('Montserrat SemiBold'),
    local('Montserrat-SemiBold'),
    url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-600.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-600.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-600.woff')
      format('woff'),
    /* Modern Browsers */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-600.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-600.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}
/* montserrat-600italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  src: url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-600italic.eot'); /* IE9 Compat Modes */
  src:
    local('Montserrat SemiBold Italic'),
    local('Montserrat-SemiBoldItalic'),
    url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-600italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-600italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-600italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-600italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-600italic.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}
/* montserrat-700italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  src: url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-700italic.eot'); /* IE9 Compat Modes */
  src:
    local('Montserrat Bold Italic'),
    local('Montserrat-BoldItalic'),
    url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-700italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-700italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-700italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-700italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-700italic.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-700.eot'); /* IE9 Compat Modes */
  src:
    local('Montserrat Bold'),
    local('Montserrat-Bold'),
    url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-700.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-700.woff')
      format('woff'),
    /* Modern Browsers */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('https://fonts.maxmind.com/montserrat/montserrat-v12-latin-700.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}
