$inputColor: #576672;

.fieldset {
  border-style: none;
  margin: 0;
  padding: 0;
}

.control-group {
  margin-bottom: 10px;
}

input.mm-input {
  -webkit-appearance: none;
  border: none;
  border-radius: 0;
  box-shadow: none;
  box-sizing: border-box;
  color: $inputColor;
  display: inline-block;
  font-family: $sansFontFamily;
  font-size: 1.125rem;
  font-weight: $weightLight;
  height: 45px;
  margin-bottom: 10px;
  padding: 0 20px;
  transition: box-shadow linear 0.2s;
  width: 100%;

  &::placeholder {
    color: $grayLight;
  }
}

.input-group-text {
  border-radius: 0;
}

.form-link {
  border-style: 0;
  margin: 0 !important;
}

textarea,
.form-control,
.form-group,
.form-select,
.form-text {
  max-width: 450px;
  width: 100%;
}

#set-password {
  max-width: 300px;
}

#reauthenticate {
  width: 300px;
}

.is-invalid,
.invalid-feedback,
.form-check-input:invalid,
.form-control:invalid,
.form-select:invalid,
.form-error {
  // This takes into account the header height and allows the
  // user to see label of the field with the error on most inputs
  scroll-margin-top: 180px;
}

textarea + .invalid-feedback {
  // This takes into account the header height and allows the
  // user to see label of the field with the error on most textareas
  // with 3 rows
  scroll-margin-top: 220px;
}
