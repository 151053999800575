.icon-group {
  display: grid;
  place-content: center;

  li {
    list-style-type: none;
    text-align: left;
  }

  ul {
    margin: 0;
    padding: 0;
  }
}

.icon-group__image {
  padding-bottom: 17px;

  img {
    height: 62px;
    width: auto;

    @include media-breakpoint-up(sm) {
      height: auto;
    }
  }
}

.icon-group__link {
  font-size: 15px;
}

.icon-group__title {
  font-size: 17px;
  font-weight: 500;
  text-align: start;
}

.icon-group__text {
  color: $color-dark-blue;
  padding-top: 12px;
}
