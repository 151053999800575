@use '../mixins';

.home__intro {
  @include page-intro--large(
    url(../images/redesign/abstract-technology-data-wave-background.webp)
  );

  background-position: 40% 105%;
  background-size: 1900px;

  @media only screen and (min-width: 1900px) {
    background-size: 130%;
  }
}

.home__intro-subheading {
  max-width: 850px;
}

.home__products-carousel .carousel-btn {
  @include media-breakpoint-up(lg) {
    width: 200px;
  }
}

.home__geoip-creator {
  @include media-breakpoint-up(sm) {
    padding: 86px 43px;
  }
}

.home__geoip-creator-image {
  height: auto;
  width: 100%;

  @include media-breakpoint-up(lg) {
    height: 350px;
    margin-left: -130px;
    width: auto;
  }
}

.home__geoip-creator-heading {
  @include media-breakpoint-up(lg) {
    width: 340px;
  }
}

.home__geoip-creator-description {
  @include media-breakpoint-up(lg) {
    width: 280px;
  }
}

.home__geoip-creator-demo {
  @include media-breakpoint-down(lg) {
    padding-top: 43px;
  }
}

.home__geoip-creator-demo__input {
  background-color: #e6f5fc;
  border: 2px solid $color-medium-blue;
  color: $color-dark-gray;
  max-width: 285px;

  &:focus {
    background-color: #e6f5fc;
  }
}

.home__customizable {
  background-image: url(../images/redesign/multi-directional-abstract-technology-data-blue-wave-background.svg);
  background-repeat: no-repeat;
  background-position: 10% 0;
  background-size: 2000px;

  @include media-breakpoint-up(sm) {
    padding: 86px 43px;
  }

  @media only screen and (min-width: 2000px) {
    background-size: 130%;
  }
}

.home__customizable-icon {
  padding: 0;

  .icon-group {
    background-color: rgba(19, 8, 50, 0.5);
    height: 175px;

    @include media-breakpoint-up(sm) {
      height: 225px;
    }
  }

  .icon-group__title {
    font-size: 13px;

    @include media-breakpoint-up(sm) {
      font-size: 17px;
    }
  }
}

.home__customizable-description {
  max-width: 525px;
}
