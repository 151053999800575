@import 'redesign/variables.scss';

@import 'maxmind/montserrat.scss';
@import 'maxmind/roboto.scss';
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-icons/font/bootstrap-icons.scss';
@import 'maxmind/variables.scss';
@import 'maxmind/mm-banners.scss';
@import 'maxmind/icons.scss';
@import 'maxmind/forms.scss';
@import 'maxmind/header.scss';
@import 'maxmind/footer.scss';
@import 'maxmind/wrappers.scss';
@import 'maxmind/geoip2-upgrade-modal.scss';

@import 'redesign/_global.scss';
@import 'redesign/mixins.scss';
@import 'redesign/colors.scss';
@import 'redesign/override-bootstrap-5.scss';
@import 'redesign/headings.scss';
@import 'redesign/legal.scss';
@import 'redesign/section.scss';
@import 'redesign/components/accordion.scss';
@import 'redesign/components/background.scss';
@import 'redesign/components/borders.scss';
@import 'redesign/components/buttons.scss';
@import 'redesign/components/cards.scss';
@import 'redesign/components/carousel.scss';
@import 'redesign/components/comparison-table.scss';
@import 'redesign/components/credit-widget.scss';
@import 'redesign/components/faqs.scss';
@import 'redesign/components/geoip-correction-forms.scss';
@import 'redesign/components/geoip-demo-widget.scss';
@import 'redesign/components/geoip-demo-form.scss';
@import 'redesign/components/enterprise-comparison.scss';
@import 'redesign/components/icons.scss';
@import 'redesign/components/list.scss';
@import 'redesign/components/product.scss';
@import 'redesign/components/pricing-checkmarks.scss';
@import 'redesign/components/subnav.scss';
@import 'redesign/components/tooltip.scss';
@import 'redesign/pages/accuracy-comparison.scss';
@import 'redesign/pages/affiliate-program.scss';
@import 'redesign/pages/anonymous-ip.scss';
@import 'redesign/pages/company.scss';
@import 'redesign/pages/commitment-to-security.scss';
@import 'redesign/pages/enterprise-connect.scss';
@import 'redesign/pages/enterprise-database.scss';
@import 'redesign/pages/fraud-prevention.scss';
@import 'redesign/pages/geoip-demo.scss';
@import 'redesign/pages/geoip-exchange.scss';
@import 'redesign/pages/home.scss';
@import 'redesign/pages/ip-geolocation-landing.scss';
@import 'redesign/pages/proxy-detection-landing.scss';
@import 'redesign/pages/geoip-databases.scss';
@import 'redesign/pages/geoip-web-services.scss';
@import 'redesign/pages/geolite-free-ip-geolocation-data.scss';
@import 'redesign/pages/request-service-trial.scss';
@import 'redesign/pages/working-at-maxmind.scss';

// HubSpot
@import 'hubspot.scss';

// Recaptcha
@import 'recaptcha.scss';

*,
*:before,
*:after {
  box-sizing: border-box;
}

/* Need to override styles from default layout */
.page-container {
  margin: 0;
  max-width: none;
  padding: 0;
}

.content-container {
  padding: 0;
}

.page-row {
  margin: 0;
}

.asterisk__wrapper {
  margin-left: 14px;
}

.asterisk:before {
  content: '*';
  display: inline-block;
  margin-left: -10px;
  position: relative;
  top: 2px;
  width: 10px;
}

.scroll-margin-top {
  scroll-margin-top: 80px;
}

.scroll-margin-top--subnav {
  scroll-margin-top: 140px;
}
