.faqs__accordion-button {
  background-color: $color-white !important;
  padding: 20px 0;

  &:focus {
    box-shadow: none;
    color: $color-dark-blue;
  }

  &:after {
    background-image: url(../images/redesign/plus-circle.svg);
    color: $color-dark-blue;
    font-size: 16px;
    position: unset;
    transform: none;
  }

  &:not(.collapsed) {
    background-color: $color-white;
    box-shadow: none;
    color: $color-dark-blue;

    &:after {
      background-image: none;
      background-image: url(../images/redesign/dash-circle.svg);
      transform: none;
    }
  }
}

.faqs__accordion-body {
  padding: 20px 0;
}

.faqs__accordion-item {
  border-bottom: 1px solid $color-dark-blue;
}
