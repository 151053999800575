.request-service-trial__intro {
  background-color: $color-dark-blue;

  @include page-intro--small(
    url(../images/redesign/circuit-digital-background.svg)
  );
}

.request-service-trial__form {
  box-shadow: 0px 3px 6px $box-shadow-color;
}

.request-service-trial__form .form-label,
.request-service-trial__form .form-text {
  max-width: 450px;
}

.request-service-trial__form .form-label {
  color: $color-dark-blue;
  font-weight: 500;
}

.request-service-trial__form .form-control,
.request-service-trial__form .form-select {
  border: 1px solid $color-dark-blue;

  &:focus {
    outline: $color-light-blue solid 2px;
  }
}

.request-service-trial__form .form-check-input {
  border: 1px solid $color-dark-blue;
}

.request-service-trial__form textarea {
  resize: none;

  &:focus {
    outline: $color-light-blue solid 2px;
  }
}

.request-service-trial__confirmation-btn {
  width: 215px;
}
