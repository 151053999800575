.geoip-databases__downloadable-icons {
  display: flex;
  column-gap: 15px;
  flex-wrap: wrap;
  justify-content: space-evenly;
  row-gap: 30px;

  .icon-group {
    grid-template-rows: 100px 30px auto;
    height: 100%;
    width: 162px;
  }
}

.geoip-databases__pricing-disclaimer {
  background-color: $bg-light-blue;
  border: 1px solid #707070;
  border-radius: 8px;
  padding: 20px 30px;
}

.geoip-databases__price-toggle-wrapper {
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: end;
  margin: auto;
  max-width: 800px;
}

.geoip-databases__price-toggle {
  background-color: $color-white !important;
  border-color: $color-light-gray !important;
  box-shadow: none !important;
  height: 20px;
}

.geoip-databases__price-toggle.form-check-input {
  width: 40px;
}
