.tooltip.show {
  opacity: 1;
  color: $color-dark-blue;
}

.tooltip-inner {
  background-color: $color-white;
  color: $color-dark-blue;
  border: 2px solid $color-light-gray;
  box-shadow: 0px 3px 6px #ffffff29;
  font-family: 'Montserrat', sans-serif;
  text-align: left;
}

.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: $color-light-gray;
}

.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: $color-light-gray;
}

.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: $color-light-gray;
}

.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: $color-light-gray;
}
