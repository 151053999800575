.legal-page {
  color: $color-dark-blue;

  li {
    padding-bottom: 12px;
  }

  h3 {
    color: $color-medium-blue;
    padding-top: 40px;
  }

  h4 {
    padding-top: 20px;
  }

  .form-control,
  .form-select {
    border: 1px solid $color-dark-blue;
    background-color: $bg-bright-blue;
  }

  .form-check-input {
    border: 1px solid $color-dark-blue;
  }
}

.legal-page__heading {
  color: $color-white;
  padding-top: 1rem !important;
}
