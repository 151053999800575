.enterprise-database__use-cases-icon-groups {
  gap: 20px;

  @include media-breakpoint-up(md) {
    gap: 48px;
  }
}

.enterprise-database__use-cases-icon-group {
  display: grid;

  @include media-breakpoint-down(md) {
    gap: 15px;
    grid-template-columns: 85px 1fr;
  }
}

.enterprise-database__icon-group {
  align-items: center;
  display: flex;
  gap: 10px;
}

.enterprise-database__overview-check {
  color: $color-green;
  font-size: 24px;
}

.enterprise-database__technical-details {
  background: linear-gradient(180deg, #fdffff 0%, $bg-light-blue 100%);
}

.enterprise-database__workflow-icons {
  display: flex;
  column-gap: 15px;
  flex-wrap: wrap;
  justify-content: space-evenly;
  row-gap: 30px;

  .icon-group {
    grid-template-rows: 100px 30px auto;
    height: 100%;
    width: 162px;
  }
}

.enterprise-database__accordion-list-item {
  @include media-breakpoint-up(sm) {
    padding: 0 40px;
  }
}

.enterprise-database__comparison-table .comparison-table__heading {
  font-size: 22px;
  height: auto;
}
