.working-at-maxmind__image {
  height: 100%;
  margin-bottom: 40px;
  width: 100%;
}

.working-at-maxmind__tabs-wrapper {
  border-radius: 6px;
  box-shadow: 0px 3px 6px $box-shadow-color;
  display: none;

  @media only screen and (min-width: 700px) {
    display: block;
  }
}

.working-at-maxmind__nav-tabs {
  border-bottom: 1px solid $bg-bright-blue;
}

.working-at-maxmind__tab-content {
  background-color: $bg-bright-blue;
  padding: 30px;
  margin-bottom: 40px;
}

.working-at-maxmind__nav-link {
  background-color: #e2f3f7;
  border-radius: 6px;
  box-shadow: 0px 3px 6px $box-shadow-color;
  color: $color-dark-blue;
  display: grid;
  height: 100%;
  gap: 20px;
  grid-template-rows: max-content;
  justify-items: center;
  font-size: 20px;
  font-weight: 600;
  width: 100%;

  &:hover {
    background-color: $bg-bright-blue;
    border-color: $bg-bright-blue !important;
    color: $color-link-hover;
  }
}

.working-at-maxmind__nav-link.active {
  background-color: $bg-bright-blue !important;
  border-color: $bg-bright-blue !important;
  box-shadow: none;
}

.working-at-maxmind__list-item {
  width: 25%;
}

.working-at-maxmind__cards {
  display: grid;
  gap: 24px;
  margin-bottom: 40px;

  @media only screen and (min-width: 700px) {
    display: none;
  }
}

.working-at-maxmind__card {
  background-color: $bg-bright-blue;
  border-radius: 12px;
  box-shadow: 0px 3px 6px $box-shadow-color;
  padding: 20px;
}
