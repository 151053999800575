@use '../mixins';

.carousel {
  max-width: 1200px;
  min-height: 600px;

  .carousel-control-next,
  .carousel-control-prev {
    font-size: 30px;
    height: 30px;
    opacity: 1;
    top: 200px;

    &:hover {
      color: $color-dark-blue;
    }

    @media only screen and (min-width: 768px) {
      height: 40px;
      top: 300px;
      width: 200px;
    }
  }
}

.carousel-indicators {
  position: static;

  /* Nest these styles to prevent Bootstrap styles from being prioritized */
  .carousel-btn--small {
    width: 40px;

    @include media-breakpoint-down(lg) {
      border-radius: 0;
    }
  }

  .carousel-btn {
    border: 1px solid $color-dark-blue;
    color: $color-dark-blue;
    height: 5px;
    opacity: 1;
    text-indent: 0;
    transition: none;

    @include media-breakpoint-up(lg) {
      height: 40px;
      width: 350px;
    }
  }

  .carousel-btn.active,
  .carousel-btn:hover {
    background-color: $color-dark-blue;
    color: $color-white;
    opacity: 1;
  }

  .carousel-btn--orange.active,
  .carousel-btn--orange:hover {
    background-color: $color-orange;
    border: 1px solid $color-orange;
    color: $color-white;
    opacity: 1;
  }

  .carousel-btn--large {
    height: 40px;
    width: 350px;

    @include media-breakpoint-down(md) {
      padding: 0 4px;
      width: 100%;
    }
  }
}

.carousel-image {
  height: auto;
  width: 50%;

  @include media-breakpoint-up(lg) {
    width: 80%;
  }
}
