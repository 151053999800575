body {
  color: $color-dark-gray;
  font-family: 'Montserrat', sans-serif;
}

a {
  color: $color-link;
  text-decoration: none;

  &:hover {
    color: $color-link-hover;
    text-decoration: underline;
  }
}

a,
button,
input,
select,
textarea {
  &:focus-visible {
    outline: $color-light-blue solid 2px;
  }
}
