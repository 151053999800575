@use 'sass:color';

$color-medium-blue: #4877bb;

// We block unsafe-inline on styles, which Hubspot normally needs
// to load the CSS below. As such, we are providing our own copy
// of it. It is likely we will need to update this in the future
// as they change their implementation.
html.hs-messages-widget-open.hs-messages-mobile,
html.hs-messages-widget-open.hs-messages-mobile body {
  overflow: hidden !important;
  position: relative !important;
}
html.hs-messages-widget-open.hs-messages-mobile body {
  height: 100% !important;
  margin: 0 !important;
}
#hubspot-messages-iframe-container {
  display: initial !important;
  z-index: 2147483647;
  position: fixed !important;
  bottom: 0 !important;
}
#hubspot-messages-iframe-container.widget-align-left {
  left: 0 !important;
}
#hubspot-messages-iframe-container.widget-align-right {
  right: 0 !important;
}
#hubspot-messages-iframe-container.internal {
  z-index: 1016;
}
#hubspot-messages-iframe-container.internal iframe {
  min-width: 108px;
}
#hubspot-messages-iframe-container .hs-shadow-container {
  display: initial !important;
  z-index: -1;
  position: absolute;
  width: 0;
  height: 0;
  bottom: 0;
  content: '';
}
#hubspot-messages-iframe-container .hs-shadow-container.internal {
  display: none !important;
}
#hubspot-messages-iframe-container .hs-shadow-container.active {
  width: 400px;
  height: 400px;
}
#hubspot-messages-iframe-container iframe {
  display: initial !important;
  width: 100% !important;
  height: 100% !important;
  border: none !important;
  position: absolute !important;
  bottom: 0 !important;
  right: 0 !important;
  background: transparent !important;
}

/* HubSpot form */
#hubspotForm.hbspt-form,
.hbspt-form {
  @media only screen and (min-width: 992px) {
    width: 880px;
  }

  .hs-button {
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    line-height: 12px;
    position: relative;
    text-align: center;
    transition: all 0.15s linear;
    background-color: $color-medium-blue; // $color-medium-blue
    border-color: $color-medium-blue; // $color-medium-blue
    color: #fff;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    font-size: 14px;
    padding: 12px 24px;
  }

  .hs-button:hover,
  .hs-button:focus {
    background-color: color.adjust(
      $color-medium-blue,
      $lightness: -10%
    ); // $color-medium-blue-hover
    border-color: color.adjust(
      $color-medium-blue,
      $lightness: -10%
    ); // $color-medium-blue-hover
  }

  .hs-button:active {
    background-color: color.adjust(
      $color-medium-blue,
      $lightness: -10%
    ); // $color-medium-blue-hover
    border-color: color.adjust(
      $color-medium-blue,
      $lightness: -10%
    ); // $color-medium-blue-hover
  }

  label {
    font-size: 14px;
    width: 130px;
    color: #33475b;
    display: block;
    float: none;
    width: auto;
    font-weight: 500;
    line-height: 20px;
    padding-top: 0;
    margin-bottom: 4px;
    font-family: 'Montserrat', sans-serif;
  }

  .hs-error-msgs label {
    color: #f2545b;
  }

  .hs-input {
    display: inline-block;
    width: 100%;
    height: 40px;
    padding: 9px 10px;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 22px;
    color: #33475b;
    border: 1px solid #cbd6e2;
    box-sizing: border-box;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }

  .hs-input:not([type='file']) {
    background-color: #f5f8fa;
  }

  .hs-input[type='checkbox'],
  .hs-input[type='radio'] {
    cursor: pointer;
    width: auto;
    height: auto;
    padding: 0;
    margin: 3px 5px 3px 0px;
    line-height: normal;
    border: none;
  }

  .hs-input[type='file'] {
    padding: initial;
    border: initial;
    line-height: initial;
    box-shadow: none;
  }

  input,
  select,
  textarea {
    max-width: 428px;
  }

  select.is-placeholder {
    color: #33475b;
  }

  .hs-input:-moz-placeholder {
    color: #bfbfbf;
  }

  .hs-input::-webkit-input-placeholder {
    color: #bfbfbf;
  }

  .hs-input option:disabled {
    color: #bfbfbf;
  }

  .hs-input input,
  .hs-input textarea {
    transition: border 0.2s linear;
  }

  .hs-input:focus {
    outline: none;
    border-color: rgba(82, 168, 236, 0.8);
  }

  textarea.hs-input {
    height: auto;
  }

  select[multiple].hs-input {
    height: inherit;
  }

  input.hs-input.error,
  div.field.error input,
  div.field.error textarea,
  div.field.error .chzn-choices,
  textarea.hs-input.error,
  select.hs-input.error {
    border-color: #c87872;
  }

  input.hs-input.error:focus,
  div.field.error input:focus,
  div.field.error textarea:focus,
  div.field.error .chzn-choices:focus,
  textarea.hs-input.error:focus,
  select.hs-input.error:focus {
    border-color: #b9554d;
  }

  .actions {
    margin-top: 18px;
    margin-bottom: 18px;
    padding: 17px 0px;
  }

  .inputs-list {
    margin: 0 !important;
    width: 100%;
    padding-left: 5px;
  }

  .inputs-list > li {
    display: block;
    padding: 0;
    width: 100%;
    padding-top: 0;
  }

  .inputs-list label {
    float: none;
    width: auto;
    padding: 0;
    line-height: 18px;
    white-space: normal;
    font-weight: normal;
  }

  .inputs-list:first-child {
    padding-top: 6px;
  }

  .inputs-list > li + li {
    padding-top: 2px;
  }

  ul.no-list {
    list-style: none;
  }

  .field {
    margin-bottom: 18px;
    width: 100%;
  }

  fieldset {
    max-width: none;
    width: 100%;
  }

  .hs-field-desc {
    color: #777777;
    margin: 0px 0px 6px;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
  }

  .hs-form-required {
    color: red;
  }

  .hs-richtext {
    margin-bottom: 3px;
    font-family: 'Montserrat', sans-serif;
    line-height: 24px;
    font-size: 14px;
    color: #33475b;
  }

  .hs-richtext hr {
    margin-left: 0;
    width: 91%;
  }

  .hs-custom-style
    .hs-dependent-field
    > div
    input.hs-input:not([type='checkbox']):not([type='radio']) {
    width: 100%;
  }

  form.hs-form-rtl .field {
    flex: 0 1 100%;
  }

  form.hs-form-rtl .hs-input[type='checkbox'],
  form.hs-form-rtl .hs-input[type='radio'] {
    margin: 3px 5px 3px 5px;
  }

  form.hs-form-rtl fieldset {
    display: flex;
  }

  form.hs-form-rtl fieldset[class^='form-columns-'] .input {
    margin-right: 0px;
  }

  form.hs-form-rtl ul {
    padding: 0px;
  }

  form.hs-form-rtl
    .legal-consent-container
    .hs-form-booleancheckbox-display
    input {
    width: auto;
    float: right;
  }

  form.hs-form-rtl
    .legal-consent-container
    .hs-form-booleancheckbox-display
    > span {
    margin-left: 0px;
  }

  form.hs-form-rtl .hs-dependent-field {
    display: flex;
    flex: 0 1 100%;
    flex-wrap: wrap;
  }

  .email-correction,
  .email-validation {
    padding-top: 3px;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
  }

  .email-correction a,
  .email-validation a {
    cursor: pointer;
  }

  @media (max-width: 400px),
    (min-device-width: 320px) and (max-device-width: 480px) {
    .email-correction form .form-columns-2 .hs-form-field,
    .email-correction form .form-columns-3 .hs-form-field,
    .email-validation form .form-columns-2 .hs-form-field,
    .email-validation form .form-columns-3 .hs-form-field {
      float: none;
      width: 100%;
    }

    .email-correction form .form-columns-2 .hs-form-field .hs-input,
    .email-correction form .form-columns-3 .hs-form-field .hs-input,
    .email-validation form .form-columns-2 .hs-form-field .hs-input,
    .email-validation form .form-columns-3 .hs-form-field .hs-input {
      width: 100%;
    }

    .email-correction
      form
      .form-columns-2
      .hs-form-field
      input[type='checkbox'],
    .email-correction form .form-columns-2 .hs-form-field input[type='radio'],
    .email-correction
      form
      .form-columns-3
      .hs-form-field
      input[type='checkbox'],
    .email-correction form .form-columns-3 .hs-form-field input[type='radio'],
    .email-validation
      form
      .form-columns-2
      .hs-form-field
      input[type='checkbox'],
    .email-validation form .form-columns-2 .hs-form-field input[type='radio'],
    .email-validation
      form
      .form-columns-3
      .hs-form-field
      input[type='checkbox'],
    .email-validation form .form-columns-3 .hs-form-field input[type='radio'] {
      width: 24px;
    }
  }

  .hs-button,
  .hs-form-field input[type='text'],
  .hs-form-field input[type='email'],
  .hs-form-field input[type='phone'],
  .hs-form-field input[type='number'],
  .hs-form-field input[type='tel'],
  .hs-form-field input[type='date'],
  .hs-form-field textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  .hs-default-font-element,
  .hs-main-font-element {
    font-family: 'Montserrat', sans-serif;
    line-height: normal;
  }

  .hs-form {
    width: 100%;
  }

  .hs-submit > .actions {
    text-align: left;
  }

  legend.hs-field-desc {
    font-family: 'Montserrat', sans-serif;
    color: #777777;
    font-size: 12px;
  }

  .hs-form-field label:not(.hs-error-msg) {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
  }

  .hs-form-field label:not(.hs-error-msg) {
    color: #130832;
  }

  .legal-consent-container .hs-richtext,
  .legal-consent-container label:not(.hs-error-msg) {
    font-family: 'Montserrat', sans-serif;
    color: #777777;
    font-size: 14px;
  }

  .hs-main-font-element,
  .submitted-message.hs-main-font-element {
    font-family: 'Montserrat', sans-serif;
  }

  .submitted-message {
    font-size: 16px;
    color: #130832;
    margin-bottom: 20px;
  }

  a:link,
  a:active {
    color: #0088c4;
  }

  a:visited,
  a:hover {
    color: #0088c4;
  }

  .form-columns-2 {
    display: flex;
    gap: 20px;
  }

  input:focus:invalid,
  textarea:focus:invalid,
  select:focus:invalid {
    box-shadow: none;
  }
}
