.geoip-demo__intro--geoip-databases {
  @include page-intro--large(
    url(../images/redesign/topographic-background-1.webp)
  );
}

.geoip-demo__intro--geoip-web-services {
  @include page-intro--large(
    url(../images/redesign/topographic-background-2.webp)
  );
}

.geoip-demo__intro--locate-my-ip {
  @include page-intro--small(
    url(../images/redesign/topographic-background-3.webp)
  );
}

.geoip-demo__intro--geoip-databases,
.geoip-demo__intro--geoip-web-services {
  background-position: 40% 10%;
  background-repeat: no-repeat;
  background-size: 1900px;

  @include media-breakpoint-up(md) {
    min-height: 742px;
  }

  @media only screen and (min-width: 1900px) {
    background-size: 130%;
  }
}

.page-container .geoip-demo__intro-heading {
  margin: 48px 0 82px;
  padding-top: 48px;
}

.page-container .geoip-demo__intro-subheading {
  max-width: 800px;
  padding-bottom: 70px;
}

.icon-group__image .geoip-demo__intro-image {
  display: flex;
  margin: auto;

  @include media-breakpoint-up(sm) {
    height: 82px;
    width: 82px;
  }
}
