.geoip-demo-text-column {
  height: min-content;
}

.geoip-demo-btn {
  width: 100%;

  @include media-breakpoint-up(sm) {
    width: auto;
  }
}

.geoip-web-services__why {
  background-color: $bg-bright-blue;
}

.geoip-use-cases {
  background-image: url(../images/redesign/data-points-technology-background.svg);
  background-position: 50% 80%;
  min-height: 550px;

  @include media-breakpoint-up(sm) {
    background-position: 50%;
    background-repeat: no-repeat;
  }

  @include media-breakpoint-up(md) {
    background-position: 50% 80%;
  }
}

.geoip-web-services__pricing-overview {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 2rem;
}

.geoip-web-services__pricing-statement {
  color: $color-dark-blue;
  padding-bottom: 23px !important;
  padding-top: 25px;
}

.geoip-web-services__tab-content-price {
  color: $color-dark-blue;
  font-size: 17px;
  font-weight: 600;

  @include media-breakpoint-up(sm) {
    font-size: 20px;
  }
}

.geoip-web-services__ribbon,
.geoip-web-services__triangle {
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 115%;

  @include media-breakpoint-up(md) {
    width: 135%;
  }
}

.geoip-web-services__ribbon {
  background-color: $color-orange;
  color: $color-white;
  font-size: 12px;
  font-weight: 600;
  padding: 4px;
  text-align: center;
  text-transform: uppercase;
  text-wrap: nowrap;
  z-index: 12;

  @include media-breakpoint-up(md) {
    font-size: 28px;
    padding: 12px;
  }
}

.geoip-web-services__triangle {
  border-color: #d44112 transparent transparent transparent;
  border-style: solid;
  border-width: 50px 50px 0 50px;
  top: 38px;

  @include media-breakpoint-up(md) {
    border-width: 50px 68px 0 68px;
  }

  @include media-breakpoint-up(md) {
    border-width: 50px 75px 0 75px;
    top: 58px;
  }
}
