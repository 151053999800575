.card {
  border: none;
  border-radius: 24px;
}

.card--icon {
  height: 315px;
  width: 290px;
}

.card--no-icon {
  height: 270px;
  width: 290px;
}

.card-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
  padding-bottom: 8px;
}

.card-title-link {
  color: $color-link;

  &:hover {
    color: $color-link-hover;
  }
}

.card-title-icon {
  margin-top: -20px;
}

.card-body {
  display: grid;
  grid-template-rows: 80px auto auto;
  padding: 20px;
}

.card-body--icon {
  grid-template-rows: auto min-content auto auto;
}

.card-btn {
  align-self: end;
  height: fit-content;
  width: fit-content;

  &:hover {
    text-decoration: none;
  }
}

.card-icon {
  height: 100px;
  text-align: center;

  img {
    height: auto;
    width: auto;
  }
}

.card-shadow--dark {
  box-shadow: 8px 8px 0px $box-shadow-color;
}

.card-shadow--light {
  box-shadow: 8px 8px 0px #ffffff4f;
}
