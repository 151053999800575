.section {
  margin: 0;
  padding: 23px;
  width: 100%;

  @include media-breakpoint-up(sm) {
    padding: 43px;
  }
}

.section__wrapper {
  margin: auto;
  max-width: 930px;
  width: 100%;
}

.section__wrapper--large {
  max-width: 1050px;
}

.section__intro-blue-bg {
  @include page-intro--small(url(../images/redesign/dark-blue-rectangle.svg));

  background-position: inherit;
  min-height: 280px;
}
