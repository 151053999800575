@use 'sass:color';

/* MaxMind primary color palette */
$color-light-blue: #7abae0;
$color-light-blue-hover: color.adjust($color-light-blue, $lightness: -10%);
$color-medium-blue: #4877bb;
$color-medium-blue-hover: color.adjust($color-medium-blue, $lightness: -10%);
$color-dark-blue: #130832;
$color-dark-blue-hover: color.adjust($color-dark-blue, $lightness: -10%);

/* MaxMind secondary color palette */
$color-geoip: #2ca695;
$color-geoip-hover: color.adjust($color-geoip, $lightness: -10%);
$color-minfraud: #aa48bc;
$color-minfraud-hover: color.adjust($color-minfraud, $lightness: -10%);
$color-orange: #fa6231;
$color-orange-hover: color.adjust($color-orange, $lightness: -10%);
$color-link: #007db8;
$color-link-hover: color.adjust($color-link, $lightness: -10%);

/* MaxMind gray tones */
$color-light-gray: #c9c9c9;
$color-dark-gray: #757575;
$color-white: #fff;

/* Other colors */
$color-accent-blue: #93d3e7;
$color-green: #48bc53;
$color-green-hover: color.adjust($color-green, $lightness: -10%);
$bg-light-blue: #ecf5fb;
$bg-bright-blue: #f4ffff;

$box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
$box-shadow-color: #00000029;

/* Form switches */
$form-switch-color: $color-medium-blue;
$form-switch-checked-color: $color-orange;
$form-switch-focus-color: $color-medium-blue;
$enable-cssgrid: true;

/* Form validation */
$form-valid-color: $color-dark-blue;
