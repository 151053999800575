.geoip-correction-forms__form {
  max-width: 500px;

  .form-check-input:not(:checked),
  .form-control,
  .form-select {
    background-color: $bg-bright-blue;
    border: 1px solid $color-dark-blue;

    &:focus {
      outline: $color-light-blue solid 2px;
    }

    &:disabled {
      background-color: #e9ecef;
    }
  }

  .form-control:read-only {
    background-color: #e9ecef;
  }
}
