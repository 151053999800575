.background__intro {
  @include page-intro--small(url(../images/redesign/blue-wave-background.svg));
}

.background__circuit-digital {
  @include page-intro--small(
    url(../images/redesign/circuit-digital-background.svg)
  );

  background-color: $color-dark-blue;
  min-height: 300px;
}

.background__digital-vision {
  background-image: url(../images/redesign/digital-vision-background.svg);
  background-position: right 50% bottom 0%;
  background-repeat: no-repeat;
}
