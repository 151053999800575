.credit-widget {
  background-color: $bg-bright-blue;
  border-radius: 5px;
  border: 1px solid $color-dark-blue;
  box-shadow: $box-shadow;
  max-width: 610px;
  padding: 25px;
}

.credit-widget__heading {
  font-size: 25px;
  font-weight: 600;
  padding-bottom: 17px !important;
}

.credit-widget__amount-heading,
.credit-widget__amount-queries-heading {
  color: $color-dark-blue;
  margin-bottom: 8px;
  padding-top: 12px;
}

.credit-widget__amount-heading {
  font-size: 20px;
}

.credit-widget__amount-queries-heading {
  font-weight: 600;
}

.credit-widget__select {
  appearance: none;
  background:
    url(../images/redesign/icons/chevron-down-white.svg) right 0.7em top 50% /
      0.8em no-repeat,
    0px 0px / 100% $color-dark-blue;
  border-radius: 8px;
  border: none;
  color: $color-white;
  font-size: 20px;
  font-weight: 600;
  height: 40px;
  padding: 7px;
  text-align: center;
  text-align-last: center;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;

  @include media-breakpoint-up(md) {
    min-width: 162px;
    width: auto;
  }
}

.credit-widget__input {
  background-color: transparent;
  border: 1px solid $color-dark-blue;
  border-radius: 8px;
  color: $color-dark-blue;
  height: 40px;
  padding-left: 20px;
  width: 100%;
}

.credit-widget__input-wrapper {
  position: relative;
}

.credit-widget__input-span {
  color: $color-dark-blue;
  left: 5px;
  position: absolute;
  top: 8px;
}

.credit-widget__queries {
  font-style: italic;
  height: 40px;
}

.credit-widget__purchase-btn {
  font-weight: 600;
  width: 100% !important;

  @include media-breakpoint-up(md) {
    width: 50% !important;
  }
}

.credit-widget__buy-now {
  scroll-margin-top: 140px;
}

.credit-widget .form-error {
  display: block;
}
