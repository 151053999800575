.mm-banner {
  background: $maxmindDarkBlueGray;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: 1.2em;
  margin-bottom: 0;
  padding: 15px;
  position: relative;
  text-align: center;
  z-index: 1000;
}

.mm-banner__content {
  margin: 0;
  padding: 0 20px;

  @media (min-width: $bp-sm-min) {
    padding: 0 40px;
  }
}

.mm-banner--other-account {
  background: #ff4500;
}

.mm-banner--cart {
  background: #99cc00;
}

.mm-banner__cart-msg {
  @media (min-width: $bp-sm-min) {
    margin-right: 15px;
  }
}

.mm-banner__cart-link,
.mm-banner__link {
  &,
  &:hover,
  &:focus {
    color: #fff;
  }
}

.mm-banner__cart-link {
  display: block;
  text-transform: uppercase;

  @media (min-width: $bp-sm-min) {
    display: inline;
  }
}

.mm-banner__link {
  cursor: pointer;
  &,
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
