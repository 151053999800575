.enterprise-comparison {
  tr:first-child > .enterprise-comparison__cell-header:nth-child(2),
  tr:nth-child(2) > td.enterprise-comparison__cell-feature {
    border-top-left-radius: 8px;
  }

  .enterprise-comparison__cell-header:last-of-type {
    border-top-right-radius: 8px;
  }

  tr:nth-child(7) > td.enterprise-comparison__cell-feature,
  tr:last-child > .enterprise-comparison__cell-footer:nth-child(2) {
    border-bottom-left-radius: 8px;
  }

  .enterprise-comparison__cell-footer:last-of-type {
    border-bottom-right-radius: 8px;
  }
}

.enterprise-comparison__card {
  height: 100%;
}

.enterprise-comparison__card-title {
  font-weight: 600;
  padding-bottom: 12px !important;
}

.enterprise-comparison__card-btn {
  align-self: end;
  display: grid;
  height: min-content;
}

.enterprise-comparison__card-list {
  padding-left: 20px;
}

.enterprise-comparison__card-list-item {
  display: grid;
  gap: 10px;
  grid-template-columns: 20px auto;
  list-style: none;
  margin-bottom: 5px;
}

.enterprise-comparison__card-check {
  color: $color-green;
  font-size: 20px;
  padding-bottom: 4px;
}

.enterprise-comparison__card-x {
  padding-bottom: 6px;
}

.enterprise-comparison__card-check,
.enterprise-comparison__card-x {
  display: grid;
  place-content: center;
}

.enterprise-comparison__card-text {
  color: $color-dark-blue;
  border-bottom: 1px dashed;
  padding-bottom: 4px;
  width: max-content;

  &:hover {
    color: $color-dark-blue;
    text-decoration: none;
  }
}

.enterprise-comparison__card-body {
  grid-template-rows: auto;
}

.enterprise-comparison__no-outline {
  outline: 0 !important;
}
