.geolite-free-ip-geolocation-data__blue-dot-background {
  background-image: url(../images/redesign/blue-dot-background.svg);
  background-size: cover;
}

.geolite-free-ip-geolocation-data__grid {
  @include media-breakpoint-up(lg) {
    column-gap: 5em;
  }
}

.geolite-free-ip-geolocation-data__grid-btn {
  height: 100%;
  place-content: center;
}
