.accordion-wrapper {
  border-radius: 8px;
  box-shadow: 0px 3px 6px $box-shadow-color;
}

.accordion-button {
  color: $color-dark-blue;
  font-weight: 600;

  &:not(.collapsed) {
    background-color: transparent;
    box-shadow: none;
    color: $color-dark-blue;

    &:after {
      transform: rotate(180deg);
    }
  }

  @include media-breakpoint-up(sm) {
    padding-left: 60px;

    &:after {
      left: 20px;
      position: absolute;
      transform: rotate(270deg);
    }
  }
}

.accordion-header {
  padding-bottom: 0 !important;
}

.accordion-item {
  border: 1px solid $color-dark-blue;
}
