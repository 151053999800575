@use '../mixins';

.affiliate-program__intro {
  @include page-intro--large(url(../images/redesign/person-using-laptop.webp));

  background-repeat: no-repeat;
  background-size: cover;

  p {
    margin-bottom: 0;
  }
}

.affiliate-program__intro-heading {
  padding-right: 20px;
  position: absolute;
  top: 100px;

  @media only screen and (min-width: 415px) {
    top: 200px;
  }
}

.affiliate-program__card-wrapper {
  left: 50%;
  max-width: 910px;
  padding: 0 20px;
  position: absolute;
  top: 750px;
  transform: translate(-50%, -50%);
  width: 100%;

  @media only screen and (min-width: 415px) {
    top: 700px;
  }

  @media only screen and (min-width: 622px) {
    top: 615px;
  }
}

.affiliate-program__intro-card {
  height: auto;
  width: 100%;

  h3 {
    padding-bottom: 17px;
  }

  .card-body {
    padding: 30px 24px;

    @include media-breakpoint-up(lg) {
      padding: 38px 68px;
    }
  }
}

.affiliate-program__overview-geoip {
  margin-top: 400px;

  @media only screen and (min-width: 500px) {
    margin-top: 300px;
  }

  @media only screen and (min-width: 625px) {
    margin-top: 100px;
  }
}

.affiliate-program__overview-minfraud {
  padding-bottom: 86px;
}

.affiliate-program__support {
  background-color: #142454;
  background-image: url(../images/redesign/wavy-dots-bg.webp);
  background-position: center;
  background-repeat: no-repeat;

  h1 {
    padding: 86px 0 32px 0;
  }

  h2:nth-child(2) {
    padding-bottom: 50px;
  }

  @media only screen and (min-width: 1660px) {
    background-size: cover;
  }
}

.affiliate-program__email-link {
  color: #77a2d8;
}

.affiliate-program__guides .btn {
  width: 200px;
}
