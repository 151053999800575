@use '../mixins';

.ip-geolocation-landing__intro {
  @include page-intro--small(
    url(../images/redesign/abstract-technology-data-blue-wave-background.svg)
  );
}

.ip-geolocation-landing__affiliate {
  background-color: $bg-light-blue;
}
