.commitment-to-security__heading {
  @include media-breakpoint-down(sm) {
    font-size: 40px !important;
  }
}

.commitment-to-security__card {
  background-color: $bg-bright-blue;
  border-radius: 12px;
  box-shadow: 0px 3px 6px $box-shadow-color;
  padding: 20px;

  @include media-breakpoint-up(md) {
    padding: 40px;
  }
}

.commitment-to-security__image-wrapper {
  @include media-breakpoint-up(md) {
    max-height: 220px;
  }
}

.commitment-to-security__image {
  height: 100%;

  @include media-breakpoint-up(md) {
    width: 100%;
  }
}
