.company__heading {
  color: $color-dark-blue;
  max-width: 420px;
  padding-bottom: 17px !important;
}

.company__image-wrapper {
  align-items: center;
  background-color: #e2f3f7;
  border-radius: 8px;
  display: flex;
  justify-content: center;

  @include media-breakpoint-up(sm) {
    height: 90px;
    width: 100px;
  }
}

.company__image {
  padding: 10px;

  @include media-breakpoint-up(sm) {
    padding: 5px;
  }
}
