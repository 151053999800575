.account__upgrade-modal-form-container {
  margin: 15px 0;
}

.account__upgrade-modal-form {
  margin: 0 0 12px 70px;
  width: 220px;
}

.account__upgrade-modal-term {
  float: left;
  margin: 9px 10px 0 0;
}

.account__upgrade-modal-button {
  float: right;
  min-width: 120px;
}
