@use '../mixins';

.fraud-prevention__intro-overview,
.fraud-prevention__intro-platforms,
.fraud-prevention__intro-pricing {
  @include page-intro--small(
    url(../images/redesign/purple-wave-background.svg)
  );
}

.fraud-prevention__intro-ecommerce {
  @include page-intro--large(
    url(../images/redesign/blue-wave-background-ecommerce.svg)
  );

  background-position: 40% 105%;
  background-size: 1900px;

  @media only screen and (min-width: 1900px) {
    background-size: 130%;
  }
}

.fraud-prevention__dots-background {
  background-color: $color-dark-blue;
  background-image: url(../images/redesign/data-points-technology-background.svg);
  background-position: 50% 80%;
  min-height: 550px;

  @include media-breakpoint-up(sm) {
    background-position: 50%;
    background-repeat: no-repeat;
  }

  @include media-breakpoint-up(md) {
    background-position: 50% 80%;
  }
}

.fraud-prevention__learn-more-card {
  height: 100%;
  max-width: 450px;
  min-height: 280px;
  width: 100%;
}

.fraud-prevention__learn-more-card-body {
  display: grid;
  grid-template-rows: auto auto min-content;
  padding: 24px;
}

.fraud-prevention__learn-more-card-text {
  color: $color-dark-gray;
  max-width: 350px;
}

.fraud-prevention__use-cases-image,
.fraud-prevention__enrich-data-image {
  height: auto;
  width: 100%;

  @include media-breakpoint-down(lg) {
    max-width: 300px;
  }
}

.fraud-prevention__card-body {
  grid-template-rows: 90px auto min-content;
}

.fraud-prevention__card-body--no-icon {
  grid-template-rows: 130px min-content;
  width: 270px;
}

.fraud-prevention__pricing-card {
  border: 1px solid $color-light-gray;
  height: 100%;
  width: 100%;

  @media only screen and (min-width: 705px) {
    max-width: 290px;
  }
}

.fraud-prevention__pricing-card-body {
  display: grid;
  height: 100%;
  padding: 20px;
}

.page-container .fraud-prevention__list-heading {
  color: $color-dark-blue;
  padding-bottom: 24px;
}

.fraud-prevention__use-cases-grid {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;

  @include media-breakpoint-up(md) {
    column-gap: 20px;
    grid-template-columns: 1fr 1fr;
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr;
  }
}

.fraud-prevention__list-checkmarks {
  padding-left: 0;

  li {
    display: flex;
    gap: 10px;

    &::before {
      content: '\F633';
      color: $color-green;
      font-family: 'bootstrap-icons';
      font-size: 17px;
    }
  }
}

.fraud-prevention__purple-dot-background {
  background-image: url(../images/redesign/purple-dot-background.svg);
  background-size: cover;
}

.fraud-prevention__ecommerce-image {
  height: auto;
  max-width: 250px;
  padding-bottom: 20px;
  width: 100%;

  @include media-breakpoint-up(md) {
    max-width: max-content;
    padding-bottom: 0;
  }
}

.fraud-prevention__data-attribute-icon {
  height: 30px;
  width: 30px;
}

.fraud-prevention__platforms-image {
  height: auto;
  max-width: 350px;
  padding-bottom: 20px;
  width: 100%;

  @include media-breakpoint-up(md) {
    height: auto;
    max-width: 570px;
  }
}

.fraud-prevention__btn-sm-d-none {
  @media only screen and (max-width: 400px) {
    display: none;
  }
}
