//
// Variables
// --------------------------------------------------

// Global values
// --------------------------------------------------

// Breakpoints
// -------------------------

@use 'sass:math';

$bp-xxs-max: 479px;
$bp-xs-max: 767px;
$bp-sm-min: 768px;
$bp-sm-max: 991px;
$bp-md-min: 992px;
$bp-lg-min: 1200px;

// Header (full width)
$bp-inline-header-min: 1500px;

// Start new base styles
// TODO (during marketing redesign):
//  - Re-evaluate variable names immediately below.
//  - Re-evalulate old/unused variables further below.
// -------------------------

// Link colours
// -------------------------
$secondaryLinkColor: #ea7600;
$secondaryLinkHoverColor: #cb510a;

// Base colours
// -------------------------
$mmBlue: #118be9;
$mmDarkGray: #344653;
$mmGray: #45535f;
$mmLightGray: #576672;

// Product colours
// -------------------------
$mmGeoipDB: #0066bb;
$mmMinfraud: #a2007d;

// End new base styles

// Base colours
// -------------------------

$grayLight: #999;

$maxmindBlueGray: #e8ecf1;
$maxmindDarkBlueGray: #364956;

$maxmindBrightBlue: #00aeef;
$maxmindMediumBlue: #008fc6;

// Scaffolding
// -------------------------
$textColor: $maxmindDarkBlueGray;

// Links
// -------------------------
$linkColorHover: $maxmindBrightBlue;

// Typography
// -------------------------
$sansFontFamily: 'Roboto', Arial, Helvetica, sans-serif;
$displayFontFamily: 'Montserrat', Arial, Helvetica, sans-serif;

$baseFontSize: 15px;
$baseLineHeight: 20px;

$contentLineHeight: math.div($baseLineHeight, $baseFontSize) + 0em;

$weightLight: 300;
$weightNormal: 400;
$weightMedium: 500;
$weightBold: 700;

// Tables
// -------------------------
$tableBackgroundAccent: $maxmindBlueGray; // for striping

// COMPONENT VARIABLES
// --------------------------------------------------

// CTA
// -------------------------
$ctaBackground: #f8f9f7;
$ctaBorderColor: #567da7;
$ctaHeadingColor: #3e83b7;

// Form states and alerts
// -------------------------
$warningBackground: #fcf8e3;

$errorText: #b94a48;
$errorBackground: #f2dede;

$successBackground: #dff0d8;

// Account
// -------------------------

$dbPricingColor: #999;
