.footer {
  color: $color-dark-blue;
  font-family: $displayFontFamily;
  font-size: 14px;
  line-height: 20px;
  margin: auto;
  max-width: 1380px;
  padding: 0 20px;
  width: 100%;

  @include media-breakpoint-up(sm) {
    padding: 0 40px;
  }
}

.footer__body {
  border-top: 1px solid $color-dark-blue;
  margin-top: 40px;
}

.footer__links {
  padding-top: 20px;
}

/* Footer logo */

.footer__logo-svg {
  width: 200px;
}

/* Social links */

.footer__social {
  display: flex;
  gap: 15px;
}

.footer__social-link {
  border-radius: 50%;
  float: left;
  color: $color-dark-blue;
  font-size: 20px;

  .bi-linkedin {
    color: #0077b5;
  }
}

/* Footer nav */

.footer__nav-heading {
  color: $color-medium-blue;
  font-size: 14px;
  margin: 0 0 12px;
  text-transform: uppercase;
}

.footer__nav-list {
  list-style: none;
  padding: 0;
}

.footer__nav-item {
  align-items: center;
  display: flex;
  margin-bottom: 10px;
}

.footer__nav-link {
  color: $color-dark-blue;
  font-size: 14px;

  &:hover {
    color: $color-link-hover;
    text-decoration: underline;
  }
}

/* Legal */

.footer__legal {
  font-size: 12px;
  padding: 10px 0 40px;

  @include media-breakpoint-between(md, lg) {
    margin-top: 20px;
  }
}

.footer__legal-logo {
  margin-bottom: 16px;
}

.footer__legal-link {
  color: $color-dark-blue;
}

.footer__legal-link:hover,
.footer__legal-link:focus,
.footer__legal-link:active {
  color: $color-link-hover;
}

.footer__legal-divider {
  margin: 0 3px;
}

.footer__system-status,
.footer__ccpa {
  padding-left: 6px;
}

.footer__ccpa-icon {
  height: 14px;
}

/* Newsletter */

.footer__newsletter {
  margin-top: 40px;

  @include media-breakpoint-up(sm) {
    margin-top: 0;
  }

  @include media-breakpoint-up(md) {
    margin-top: 48px;
  }

  // HubSpot form elements. We need to override the styles in hubspot.scss.
  .hbspt-form {
    max-width: 250px;
    width: 100%;

    .hs-button {
      background-color: $color-medium-blue;
      border: 2px solid;
      border-color: $color-medium-blue;
      border-radius: 5px;
      color: $color-white;
      font-size: 14px;
      font-weight: 500;
      max-width: 250px;
      padding: 10px;
      width: 100%;

      &:active,
      &:focus,
      &:hover {
        background-color: $color-medium-blue-hover;
        border: 2px solid $color-medium-blue-hover;
        color: $color-white;
        font-size: 14px;
      }
    }

    .actions {
      padding-top: 0;
    }

    .hs-form-required {
      color: $color-dark-blue;
      padding-left: 3px;
    }

    .hs-error-msg {
      color: #dc3545;
      padding-top: 4px;
    }

    .hs_error_rollup {
      display: none;
    }

    .submitted-message {
      font-size: 14px;
      font-weight: 500;
    }

    label {
      font-weight: 400;
    }

    .hs-input {
      background-color: #e2f3f7 !important;
      border: none;
      box-shadow: 0px 3px 6px #00000029;
      font-size: 14px;
      height: 32px;

      &:focus {
        background-color: #e2f3f7;
      }
    }

    .hs-input.invalid {
      border: 1px solid #dc3545;
    }
  }
}
