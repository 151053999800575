.product {
  display: grid;
  gap: 0.5rem;

  @include media-breakpoint-up(lg) {
    grid-template-rows: 364px min-content min-content min-content;
  }
}

.product__image,
.product__picture {
  margin: 0 auto 1.5rem auto;
  max-height: 280px;

  @include media-breakpoint-down(sm) {
    max-width: 300px;
    width: 100%;
  }

  @include media-breakpoint-up(lg) {
    max-height: 340px;
  }
}

.product__image {
  height: auto;
  width: auto;

  @include media-breakpoint-up(lg) {
    margin-left: 0;
  }
}

.product__picture {
  display: flex;

  img {
    height: auto;
    object-fit: contain;
    width: 100%;
  }
}

.product__card {
  border-radius: 6px;
  box-shadow: 0px 3px 6px $box-shadow-color;
  display: grid;
  height: 100%;
  gap: 0.5rem;
  overflow: hidden;
  padding: 24px;
  position: relative;

  &::after,
  &::before {
    background-color: $color-geoip;
    content: '';
    display: block;
    height: 6px;
    position: absolute;
    width: 100%;
  }

  @include media-breakpoint-up(lg) {
    grid-template-rows: min-content min-content auto auto;
  }
}

.product__card-medium-blue {
  &::after,
  &::before {
    background-color: $color-medium-blue;
  }
}

.product__card-image {
  margin: auto;
  max-height: 150px;
}
