/* Header layout */

.header {
  background: #fff;
  border-bottom: 1px solid #ddd;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  font-family: $displayFontFamily;
  height: 78px;
  position: sticky;
  top: 0;
  transition: all 0.5s ease-out;
  width: 100%;
  z-index: 1010;
}

.header__nav {
  padding: 20px;
  max-width: 950px;
}

.header__toggle {
  @include media-breakpoint-down(md) {
    border-bottom-left-radius: 12px;
    border-top-left-radius: 12px;
    padding: 20px;
    margin-top: 75px;
    overflow-y: scroll;
  }
}

#react-container .marketing-page-subnav {
  @media (min-width: $bp-sm-min) and (orientation: portrait) {
    position: sticky;
    top: 0;
  }

  @media (min-width: $bp-md-min) {
    position: sticky;
    top: 65px;
  }
}

/* Header logo */

.header__logo {
  background-image: url(../images/maxmind-header-logo-compact-alt.svg);
  background-repeat: no-repeat;
  height: 35px;
  max-width: 200px;
  width: 100%;
}

/* Main nav */

.main-nav {
  @include media-breakpoint-up(md) {
    position: absolute;
    right: 25%;
    white-space: nowrap;
  }

  @include media-breakpoint-up(lg) {
    right: 30%;
  }
}

.main-nav__list {
  @include media-breakpoint-down(md) {
    padding-top: 20px;
  }
}

.main-nav__list,
.utility-nav__list {
  list-style: none;
  margin: 0;
}

.utility-nav__list {
  display: flex;
  padding-left: 0;
}

.utility-nav__label {
  font-size: 13px;

  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.utility-nav__label--logged-in {
  color: $color-medium-blue;
}

.main-nav__item {
  position: static;
}

.main-nav__item:not(:last-child) {
  @include media-breakpoint-down(md) {
    border-bottom: 1px solid #d6d6d6;
  }
}

.main-nav__link,
.utility-nav__link {
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 6px;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  height: 44px;
  line-height: 20px;

  &:hover,
  &:focus,
  &:active {
    color: $color-medium-blue;
    text-decoration: none;
  }
}

.main-nav__link {
  color: $color-dark-blue;

  @include media-breakpoint-down(md) {
    display: flex;
    font-size: 20px;
    justify-content: space-between;
  }

  @include media-breakpoint-up(md) {
    margin: 0 10px;
    padding: 0 10px;
  }
}

.main-nav__link.show {
  color: $color-medium-blue;
}

/* Utility nav */

.utility-nav__subnav--logged-in.show {
  max-width: 300px;
}

.utility-nav__item--search {
  @include media-breakpoint-down(md) {
    position: absolute;
    right: 70px;
    top: 17px;
  }
}

.utility-nav__link {
  border: 1px solid transparent;
  color: $color-dark-gray;
  gap: 4px;
  padding: 0 8px;
  position: relative;
  white-space: nowrap;
  z-index: 3;

  @include media-breakpoint-up(md) {
    &:hover,
    &:focus,
    &:active {
      background: #f5f6f7;
      text-decoration: none !important;
    }
  }

  &.show,
  .open > & {
    background: #f5f6f7;
    border: 1px solid #ddd;
    border-bottom: 0;
    border-radius: 6px 6px 0 0;
  }
}

.utility-nav__link:after {
  content: none;
}

/* Utility nav - Subnav */

.utility-nav__subnav {
  background: #f5f6f7;
  border: 1px solid #ddd;
  border-radius: 6px 0 6px 6px;
  box-shadow: 4px 3px 2px -2px rgba(0, 0, 0, 0.15);
  padding: 20px;
  margin-top: -1px !important;
  z-index: 2;
}

.utility-nav__subnav--search {
  width: calc(100vw - 108px);

  @include media-breakpoint-up(sm) {
    width: 500px;
  }
}

/* Utility nav - Account */
.account-sign-in__account_id {
  color: #576672;
  font-size: 0.875rem;
  line-height: 1.375rem;
  margin-bottom: 20px;
}

.account-sign-in__heading {
  color: #576672;
  font-size: 1.125rem;
  font-weight: $weightLight;
  line-height: 1.375rem;
  margin-bottom: 20px;
}

.account-sign-in__user {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.account-sign-in__link {
  display: block;
  font-size: 0.875rem;
  margin-bottom: 5px;
  text-transform: uppercase;

  &,
  &:hover,
  &:focus {
    color: $color-orange;
  }
}

.account-sign-in__hr {
  background: transparent;
  border: 0;
  border-top: 1px solid #ddd;
  margin: 30px 0;
}

/* Utility nav - Labels */

.utility-nav__label {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

/* Header system status */

.header__system-status {
  background-color: #2e3a47;
  display: none;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
}

.show-header-system-status {
  display: block;
  padding: 10px;
}

.header__system-status-content {
  align-items: center;
  color: #c0d2e4;
  display: grid;
  font-size: 14px;
  gap: 10px;
  grid-auto-flow: column;
  grid-gap: 10px;
  justify-content: center;
  position: relative;
  z-index: 1;

  a {
    color: #fff;
  }
}

.header__status-more-info:hover {
  color: #00aeef;
}

.header__status-more-info-icon {
  font-size: 12px;
}

.status-icon {
  display: none;
}

.show-status-icon {
  display: block;
}

.status-icon__warning {
  color: #f0ad4e;
}

/* Dropdown menus */

.main-nav__dropdown-menu {
  border: none;
}

.show.main-nav__dropdown-menu {
  @include media-breakpoint-up(md) {
    border-radius: 0 0 12px 12px;
    box-shadow: 0 8px 6px $box-shadow-color;
    display: flex;
    left: -28vw;
    margin-top: -1vh;
    min-width: 768px;
    padding: 20px 20px 30px 20px;
    top: 50px;
  }

  @include media-breakpoint-up(lg) {
    left: 47%;
    transform: translateX(-50%);
    min-width: 992px;
  }

  @include media-breakpoint-up(xl) {
    min-width: 1200px;
  }
}

.main-nav__link:after {
  transition: transform 0.3s;
}

// Desktop view
.show > .main-nav__link,
// Mobile / tablet view
.show.main-nav__link {
  color: $color-medium-blue;

  &::after {
    transform: rotate(-180deg);
  }
}

.main-nav__subnav-group {
  white-space: normal;

  @include media-breakpoint-down(md) {
    padding-bottom: 20px;
    padding-left: 0;
  }
}

.main-nav__subnav-item {
  text-align: left;

  @include media-breakpoint-up(md) {
    justify-content: center;
    text-align: center;
  }

  @include media-breakpoint-up(xl) {
    text-align: left;
  }
}

.main-nav__subnav-image {
  background: transparent linear-gradient(236deg, #325495 0%, #000000 100%) 0%
    0%;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  height: 82px;
  width: 82px;

  @include media-breakpoint-up(md) {
    margin: 0 auto 15px auto;
  }

  @include media-breakpoint-up(xl) {
    margin-bottom: 0;
  }

  img {
    height: 100%;
    padding: 10px;
    width: 100%;
  }
}

.main-nav__subnav-image--large {
  @include media-breakpoint-up(md) {
    height: 160px;
    width: 160px;

    img {
      padding: 20px;
    }
  }
}

.main-nav__subnav-image--small {
  @include media-breakpoint-up(md) {
    height: 95px;
    width: 95px;

    img {
      padding: 15px;
    }
  }
}

.main-nav__subnav-title {
  color: $color-dark-blue;
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 8px;
}

a.main-nav__subnav-title:hover {
  color: $color-medium-blue;
}

.main-nav__subnav-description,
.main-nav__subnav-list {
  font-size: 12px;
  line-height: 18px;
}

.main-nav__subnav-list {
  padding-left: 0;
}

.main-nav__subnav-list-item {
  list-style: none;
  padding-bottom: 4px;

  a {
    color: #335495;

    &:hover {
      color: $color-medium-blue;
    }
  }
}

/* Transparent nav */

.has-transparent-nav.header {
  background-color: transparent;
  border: none;
  box-shadow: none;

  &.has-subnav {
    background-color: $color-dark-blue;
  }
}

.has-transparent-nav {
  .header__logo {
    background-image: url(../images/maxmind-header-logo-compact-alt-white.svg);
  }

  .main-nav__link {
    @include media-breakpoint-up(md) {
      color: $color-white;
    }

    &:hover,
    &:focus-visible,
    &:active {
      background: transparent;
      color: $color-light-blue;
    }
  }

  .show > .main-nav__link {
    color: $color-light-blue;
  }

  .utility-nav__item,
  .utility-nav__link {
    color: $color-white;

    &:hover,
    &:focus,
    &:active {
      background: transparent;
      color: $color-medium-blue;
    }
  }

  .main-nav__link:not(.show) {
    @include media-breakpoint-down(md) {
      color: $color-dark-blue;

      &:hover {
        color: $color-medium-blue;
      }
    }
  }

  .utility-nav__link.show,
  .utility-nav__link--search.show {
    &:hover,
    &:focus,
    &:active {
      background: #f5f6f7;
    }
  }

  .mm-icon__logged-in {
    color: $color-medium-blue;
  }

  .mm-icon__cart--empty,
  .mm-icon__logged-out {
    @include media-breakpoint-down(md) {
      color: $color-dark-gray;
    }
  }

  .mm-icon__cart--full {
    color: #99cc00;
  }

  .mm-icon__toggler {
    color: $color-white;
  }

  .show > .mm-icon {
    color: $color-medium-blue;
  }
}
