.subnav__wrapper {
  background-color: #e3e0e0;
  position: sticky;
  // Height of <header>
  top: 78px;
  width: 100%;
  z-index: 1000;
}

.subnav {
  background-color: #e3e0e0;
  justify-content: space-between;
  margin: auto;
  max-width: 950px;
  padding: 10px 20px;
  width: 100%;
}

.subnav-item {
  border-left: 4px solid transparent;

  &:first-of-type {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:last-of-type {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  @include media-breakpoint-up(lg) {
    border-left: none;
    margin-right: 5px;
  }
}

.subnav-item:has(.active) {
  background-color: #f0efef;
  border-left: 4px solid $color-link-hover;

  @include media-breakpoint-up(lg) {
    background-color: transparent;
    border-left: none;
  }
}

.subnav-link {
  color: $color-dark-blue;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  padding: 8px;
  width: 100%;

  &:hover {
    text-decoration: none;
  }
}

.subnav-link.active {
  color: $color-link-hover;

  @include media-breakpoint-up(lg) {
    background-color: $color-dark-blue;
    border: 2px solid $color-geoip;
    border-radius: 5px;
    color: $color-white;

    &:hover {
      background-color: $color-geoip-hover;
    }
  }
}

.subnav-link--minfraud.active {
  @include media-breakpoint-up(lg) {
    border: 2px solid $color-minfraud;

    &:hover {
      background-color: $color-minfraud-hover;
    }
  }
}

.subnav-dropdown-menu.show {
  display: grid;
  margin-top: 5px;
  padding: 0;
}

.subnav-dropdown-button {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr auto;

  &.show {
    background-color: $color-dark-blue;
    color: $color-white;
  }
}

.legal-page li.subnav-item.subnav-item--legal {
  padding-bottom: 0;
}

.subnav-item--legal:has(.active) {
  border-left: 4px solid $color-link-hover;
}

.subnav-item--legal {
  margin-right: 0;

  @include media-breakpoint-up(lg) {
    &:first-of-type {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    &:last-of-type {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  .subnav-link.active {
    background-color: #f0efef;
    border: none;
    border-radius: 0;
    color: $color-dark-blue;
  }
}

.subnav-dropdown-menu--legal {
  width: 240px;
}
