.rounded-top-left {
  border-top-left-radius: 8px;
}

.rounded-top-right {
  border-top-right-radius: 8px;
}

.rounded-bottom-left {
  border-bottom-left-radius: 8px;
}

.rounded-bottom-right {
  border-bottom-right-radius: 8px;
}

.rounded-bottom-left__small {
  @include media-breakpoint-down(sm) {
    border-bottom-left-radius: 8px;
  }
}

.rounded-bottom-left__medium {
  @include media-breakpoint-down(md) {
    border-bottom-left-radius: 8px;
  }
}
