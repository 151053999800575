.comparison-table {
  border: 0;
  border-spacing: 1px;
  border-collapse: separate;
  margin: auto;

  td {
    color: $color-dark-blue;
    outline: 1px solid $color-dark-blue;
    padding: 4px 8px;

    &:first-of-type {
      background-color: $bg-light-blue;
    }

    @include media-breakpoint-down(md) {
      width: 50%;
    }
  }

  @include media-breakpoint-down(md) {
    tr:nth-child(2) > .product-cell {
      border-top-right-radius: 8px;
    }

    tr:last-child > .product-cell {
      border-bottom-right-radius: 8px;
    }
  }
}

.comparison-table--geolocation-databases {
  max-width: 800px;
  width: 100%;
}

.comparison-table__heading,
.comparison-table__description,
.comparison-table__price {
  padding: 0 17px;
}

.comparison-table__heading {
  color: $color-dark-blue;
  padding-bottom: 0 !important;
  margin: 20px 0 !important;
}

.comparison-table__heading {
  @include media-breakpoint-up(lg) {
    height: 100px;
  }
}

.comparison-table__heading--web-services {
  margin-top: 60px !important;
}

.comparison-table__head-wrapper {
  outline: 1px solid $color-dark-blue;
  padding: 0;
  position: relative;
  vertical-align: top;
}

.comparison-table__head-wrapper--3-cols {
  width: 33%;
}

.comparison-table__head-wrapper--4-cols {
  width: 25%;
}

.comparison-table__head {
  display: grid;
  grid-template-rows: 120px auto auto;
  height: 100%;
  position: absolute;
  top: 0;
}

.comparison-table__description {
  font-weight: normal;
  font-size: 15px;
}

.comparison-table__description--160 {
  @media only screen and (min-width: 992px) and (max-width: 1147px) {
    height: 270px;
  }

  @media only screen and (min-width: 1148px) {
    height: 160px;
  }
}

.comparison-table__price {
  align-self: end;
  color: $color-dark-blue;
  font-size: 18px;
}

.comparison-table__info {
  background: none;
  border: none;
  color: $color-medium-blue;
  font-size: 20px;
  padding: 0;
}

.comparison-table__info-placeholder {
  width: 20px;
}

.comparison-table__check,
.comparison-table__x {
  display: grid;
  place-content: center;
}

.comparison-table__check {
  color: $color-green;
  font-size: 30px;
}

.comparison-table__x {
  color: $color-dark-gray;
  font-size: 24px;
}

.comparison-table__attribute-wrapper {
  align-items: center;
  display: flex;
  gap: 12px;
}

.comparison-table__attribute-wrapper--large {
  @include media-breakpoint-up(md) {
    width: 205px;
  }
}

.comparison-table__attribute {
  color: $color-dark-blue;
  font-size: 17px;
  font-weight: 600;
}

.comparison-table__attribute--orange {
  color: $color-orange;
}

.product-cell {
  @include media-breakpoint-down(md) {
    height: 40px;
    width: 40%;
  }
}

.product-cell--text {
  font-size: 15px;
  padding: 15px !important;
  vertical-align: top;
}

.comparison-table__tab-btn {
  align-items: center;
  color: $color-dark-blue;
  display: flex;
  font-size: 17px;
  font-weight: 600;
  justify-content: center;
  padding: 8px;
  position: relative;
  width: 100%;
  z-index: 10;

  &:hover {
    color: $color-medium-blue;
  }

  @include media-breakpoint-up(sm) {
    font-size: 25px;
    padding: 12px 16px;
  }
}

.comparison-table__tab-btn--small {
  @include media-breakpoint-up(sm) {
    font-size: 17px;
  }
}

.comparison-table__tab-btn:not(.active) {
  background-color: $color-white;
  border: 1px solid transparent !important;
}

.comparison-table__tab-btn.active {
  background-color: $bg-light-blue !important;
  border: 1px solid $bg-light-blue !important;
  color: $color-medium-blue !important;
}

.geoip-web-services__tab-content {
  background-color: $bg-light-blue;
  border: $bg-light-blue !important;
  margin-top: -2px;
  padding: 8px;
  position: relative;
  z-index: 13;

  @include media-breakpoint-up(sm) {
    padding: 16px;
  }
}

.comparison-table__tabs {
  border-bottom: 1px solid $bg-light-blue;
  justify-content: space-between;
}

.comparison-table__list {
  list-style-type: none;
  padding-left: 16px;

  li:before {
    display: inline-block;
    content: '-';
    margin-left: -1em;
    width: 1em;
  }
}

.comparison-table__price-cell {
  display: grid;
  padding: 4px;
  row-gap: 5px;

  @include media-breakpoint-up(sm) {
    padding: 12px 20px;
  }

  .btn {
    font-size: 15px;
    font-weight: 500;
    width: 100%;

    @include media-breakpoint-up(sm) {
      font-size: 20px;
      font-weight: 600;
    }
  }
}

.comparison-table__price-cell-price {
  column-gap: 5px;
  display: grid;
  grid-template-areas:
    'dollar cents'
    'frequency frequency';
  grid-template-columns: min-content;

  @media only screen and (min-width: 882px) {
    grid-template-areas:
      'dollar cents'
      'frequency frequency';
  }
}

.comparison-table__price-cell-amount {
  color: $color-dark-blue;
  font-size: 18px;
  font-weight: 600;
  grid-area: dollar;

  @media only screen and (min-width: 390px) {
    font-size: 22px;
  }

  @media only screen and (min-width: 500px) {
    font-size: 30px;
  }

  @include media-breakpoint-up(sm) {
    font-size: 40px;
  }
}

.comparison-table__price-cell-annually {
  grid-area: frequency;
}

.comparison-table__price-cell-annually,
.comparison-table__price-cell-monthly {
  color: $color-dark-gray;
  display: flex;
  font-size: 13px;
  gap: 3px;
  grid-area: frequency;
}

.comparison-table__price-cell-superscript {
  font-size: 14px;
  font-weight: 600;
  grid-area: cents;
  position: relative;
  right: 2px;
  top: 4px;

  @media only screen and (min-width: 390px) {
    top: 6px;
  }

  @include media-breakpoint-up(sm) {
    font-size: 16px;
    top: 10px;
  }
}

.comparison-table__price-cell-billed {
  display: none;

  @media only screen and (min-width: 500px) {
    display: block;
  }
}

.comparison-table__select {
  appearance: none;
  background:
    url(../images/redesign/icons/chevron-down-dark-blue.svg) right 0.7em top
      50% / 0.8em no-repeat,
    0px 0px / 100% $color-white;
  border: 1px solid #707070;
  border-radius: 8px;
  font-size: 14px;
  margin-bottom: 10px;
  padding: 5px 8px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;

  @include media-breakpoint-up(sm) {
    font-size: 16px;
  }
}

.comparison-table__mobile-heading {
  font-size: 17px;
  font-weight: 600;
  text-align: center;
}

.comparison-table__mobile-span {
  display: none;

  @media only screen and (min-width: 500px) {
    display: block;
  }
}

.comparison-table__card {
  background-color: $bg-light-blue;
  padding: 16px;
  margin: 10px 10px 10px 0;
}

.comparison-table__purchase-btn:disabled {
  background-color: $color-green;
  border: 1px solid $color-green;
  color: $color-white;
  opacity: 100%;
}
