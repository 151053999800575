.geoip-demo-widget__table {
  border: none;
  border-collapse: separate;
  border-radius: 8px;
  border-spacing: 0;
  box-shadow: $box-shadow;
  font-family: 'Source Code Pro', monospace;
  font-size: 15px;

  tr:nth-child(even) .geoip-demo-widget__attribute {
    background-color: #312a4f;
  }

  tr:nth-child(even) .geoip-demo-widget__result {
    background-color: #d9e8f0;
  }
}

.geoip-demo-widget__attribute,
.geoip-demo-widget__result {
  padding: 8px 16px;

  @include media-breakpoint-up(sm) {
    padding: 16px 32px;
  }
}

.geoip-demo-widget__attribute {
  background-color: $color-dark-blue;
  color: $color-white;
  width: 40%;
}

.geoip-demo-widget__result {
  background-color: $bg-light-blue;
  color: $color-dark-blue;
  width: 60%;
  word-break: break-word;
}

.geoip-demo-btn {
  width: 100%;

  @include media-breakpoint-up(sm) {
    width: auto;
  }
}

.geoip-demo-widget__tooltip {
  border-bottom: 2px dashed $color-white;
  color: $color-white;
  line-height: 2em;
  padding-bottom: 5px;

  &:hover {
    color: $color-white;
    text-decoration: none;
  }
}
