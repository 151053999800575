.mm-icon {
  display: grid;
  place-content: center;

  @include media-breakpoint-up(md) {
    height: 24px;
    width: 24px;
  }
}

.mm-icon__logged-in,
.mm-icon__logged-out {
  font-size: 22px;

  @include media-breakpoint-down(md) {
    font-size: 30px;
  }
}

.mm-icon__logged-in {
  color: $color-medium-blue;
}

.mm-icon__search {
  font-size: 22px;

  @include media-breakpoint-up(md) {
    font-size: 18px;
  }
}

.utility-nav__link--search .mm-icon__search {
  pointer-events: none;
}

.mm-icon__cart {
  font-size: 20px;

  @include media-breakpoint-down(md) {
    font-size: 26px;
  }
}

.mm-icon__cart--full {
  color: #99cc00;
}

.mm-icon__toggler {
  border: none;
  font-size: 30px;
  position: absolute;
  right: 20px;
  top: 20px;

  &:focus,
  &:focus-visible {
    // Need to override Bootstrap styling
    box-shadow: 0 0 0 $color-light-blue;
    outline: $color-light-blue solid 2px;
  }
}

.mm-icon__close {
  font-size: 20px;
}
