.list {
  padding-left: 16px;

  li {
    padding-bottom: 10px;
  }

  li::marker {
    color: $color-dark-blue;
  }
}
