.anonymous-ip__use-cases-icon-group {
  place-content: start;
}

.anonymous-ip__technical-details {
  background: linear-gradient(180deg, #fdffff 0%, #ecf5fb 100%);
}

.anonymous-ip__technical-details-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  row-gap: 30px;

  @media only screen and (min-width: 821px) {
    justify-content: space-between;
  }

  .icon-group {
    grid-template-rows: 100px 30px auto;
    height: 100%;
    width: 180px;
  }
}
